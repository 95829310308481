import { observable, action, runInAction } from 'mobx';
import userService from '@/service/user';
import table from './table';

class UserStore {
  //用户管理
  @observable deptTree;
  @observable groupTree;
  @observable groupList;
  @observable currentGroup;
  @observable groupUserTable = new table();
  @observable currentStaffId;
  @observable currentStaffInfo;
  @observable allRoleList;
  @observable categoryList;
  @observable initFlag = false;

  @action setCurrentStaffId = (id) => {
    this.currentStaffId = id;
  };

  @action resetInitFlag = () => {
    this.initFlag = false;
  };

  //获取群组树
  @action getGroupTree = (init) => {
    userService.getGroupTree().then((rs) => {
      runInAction(() => {
        this.groupTree = rs.data;
        this.groupList = [];
        const generateList = (data) => {
          for (let i = 0; i < data.length; i++) {
            const node = data[i];
            this.groupList.push(node);
            if (node.children) {
              generateList(node.children);
            }
          }
        };
        rs.data && generateList(rs.data);
        if (init && rs.data[0]) {
          this.initFlag = true;
          this.setCurrentGroup(rs.data[0]);
        }
      });
    });
  };

  @action setCurrentGroup = (currentGroup = {}) => {
    this.currentGroup = currentGroup;
    // this.groupUserTable.onParamsChange({ groupId: currentGroup.groupId || '' });
    // if (!currentGroup || (currentGroup && currentGroup.groupId == undefined)) {
    //   this.groupUserTable.list = [];
    // } else {
    //   this.groupUserTable.getList();
    // }
  };
  //移动群组
  @action moveGroup = (id, moveToId) => {
    return userService.moveGroup({ id, moveToId });
  };
  //删除群组
  @action deleteGroup = (id) => {
    return userService.deleteGroup({ id });
  };
  //修改群组
  @action updateGroup = (id, departmentName) => {
    return userService.updateGroup({ id, departmentName });
  };
  //添加群组
  @action addGroup = (parentId, departmentName) => {
    return userService.addGroup({ parentId, departmentName });
  };
}

export default new UserStore();
