import React, { Component } from 'react';
import { Input } from 'antd';
import styles from './index.less';
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
  }
  inputChange = (e) => {
    const { value } = e.target;
    this.props.onChange && this.props.onChange(value);
  };
  onPressEnter = (e) => {
    console.log(e, window.event);
    var evt = window.event || e;
    if (evt.keyCode == 13) {
      this.props.onPressEnter && this.props.onPressEnter();
    }
  };
  render() {
    const {
      placeholder = '请输入',
      type = 'text',
      name = '',
      value
    } = this.props;
    return (
      <div className={styles.cstInputWrap}>
        <input
          className={styles.cstInput}
          type={type}
          value={value}
          onChange={this.inputChange}
          onKeyDown={this.onPressEnter}
          name={name}
          placeholder={placeholder}
          autoComplete={this.props.autoComplete || 'off'}
          readOnly={this.props.readOnly}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
        <span className={styles.cstUnderline}></span>
      </div>
    );
  }
}
