import { observable, flow, action, toJS } from 'mobx';
import _ from 'lodash';
import service from '@/service/account';

import BaseStore from '../magicPackages/BaseStore';

class PostManageStore extends BaseStore {
  constructor() {
    super(service, {
      getVerificationImg: {
        observableProp: 'charImage',
        serviceMethodName: 'getVerificationImg',
        successMessage: ''
      }
    });
  }
}

export default new PostManageStore();
