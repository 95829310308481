let hostname = window.location.hostname;
let origin = window.location.origin;
let config;

let commonConfig = {
  ttsConfig: {
    1: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/avator-female-200-200.png',
    0: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/avator-male-200-200.png',
    2: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/avator-kid-200-200.png'
  },
  loginConfig: {
    1: 'http://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/smart-phone-call_new.png',
    2: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/loginbg.png',
    3: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/Welcome.png',
    4: 'http://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/logo_bottom_new.png'
  },
  loginBgConfig: {
    1: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/coldcall/release/1.1.3/bg1.be871626.png',
    2: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/coldcall/release/1.1.3/bg2.3888d5dd.png',
    3: 'https://yunli-fe.oss-cn-beijing.aliyuncs.com/coldcall/release/1.1.3/bg3.ce0aae2c.png'
  },
  logoConfig: {
    1: 'http://yunli-fe.oss-cn-beijing.aliyuncs.com/assets/logo-name_new.png'
  }
};

let personalPre = `${origin}:8181`; //常规
// let personalPre = 'http://221.181.129.129:10982'; //河南疫情
let personalConfig = {
  ttsConfig: {
    1: `${personalPre}/front/static/media/avator-female-200-200.png`,
    0: `${personalPre}/front/static/media/avator-male-200-200.png`,
    2: `${personalPre}/front/static/media/avator-kid-200-200.png`
  },
  loginConfig: {
    1: `${personalPre}/front/static/media/smart-phone-call-bg.png`,
    2: `${personalPre}/front/static/media/loginbg.png`,
    3: `${personalPre}/front/static/media/Welcome.png`,
    4: `${personalPre}/front/static/media/logo_bottom.png`
  },
  loginBgConfig: {
    1: `${personalPre}/front/static/media/bg1.png`,
    2: `${personalPre}/front/static/media/bg2.png`,
    3: `${personalPre}/front/static/media/bg3.png`
  },
  logoConfig: {
    1: `${personalPre}/front/static/media/logo-name.png`
  }
};

if (
  hostname == 'localhost' ||
  hostname == 'call.console.yunlizhihui.com' ||
  hostname == 'callpre.console.yunlizhihui.com'
) {
  config = commonConfig;
} else {
  config = personalConfig;
}

export default config;
