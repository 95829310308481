import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import stores from './stores';
import 'moment/locale/zh-cn';

import Login from './containers/Login';
import * as serviceWorker from './serviceWorker';
import './index.less';
// node环境变量挂在到window上
window.NODE_ENV = process.env.NODE_ENV;
window.BASE_URL = window.NODE_ENV === 'development' ? '/' : '/userService';
// window.BASE_URL = '/';
try {
  let contextPath = window.contextPath;
  if (contextPath) {
    contextPath = JSON.parse(contextPath.replace(/&quot;/g, '"'));
    const { appCtx, staticCtx, logo, cityNo, bgImage } = contextPath;
    window.appCtx = appCtx;
    window.staticCtx = staticCtx;
    window.mainLogo = logo;
    window.cityNo = cityNo;
    window.bgImage = bgImage;
  } else {
    window.appCtx = '';
    window.staticCtx = null;
    window.mainLogo = null;
    window.cityNo = null;
    window.bgImage = null;
  }
} catch (error) {
  window.appCtx = '';
  window.staticCtx = null;
  window.mainLogo = null;
  window.cityNo = null;
  window.bgImage = null;
}
let dom = (
  <Provider {...stores}>
    <ConfigProvider locale={zhCN}>
      <Login />
    </ConfigProvider>
  </Provider>
);

ReactDOM.render(dom, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
