import request, { get, post } from './xhr/fetch';
import { groupTreeData } from './mockData';

//获取群组树
export const getGroupTree = function (param) {
  // return Promise.resolve(groupTreeData);
  return get('/user/center/department/GetPage.json', param);
};

//创建群组
export const addGroup = function (param) {
  return post('/user/center/department/Add.json', param);
};
//修改群组
export const updateGroup = function (param) {
  return post('/user/center/department/Update.json', param);
};
//删除群组
export const deleteGroup = function (param) {
  return post('/user/center/department/Delete.json', param);
};
//移动群组
export const moveGroup = function (param) {
  return post('/user/center/department/move.json', param);
};
export default {
  getGroupTree,
  addGroup,
  updateGroup,
  deleteGroup,
  moveGroup
};
