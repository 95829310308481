import BaseService, { postFn } from '../magicPackages/BaseService';

class RoleManageService extends BaseService {
  constructor() {
    super('role', {
      getRoleList: '/user/center/role/GetRoleList.json'
    });
  }
}

export default new RoleManageService();
