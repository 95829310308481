import React, { Component } from 'react';
import { Input, Row, Col, Button, Icon, Form } from 'antd';
import { inject, observer } from 'mobx-react';
import setEncrypt from '@/utils/encrypt';
import qs from 'qs';
import _ from 'lodash';
import style from './index.less';
import service from '@/service/account';
import config from '@/common/url';
// import LogoSvg from './assets/logo.svg';
import logo from '@/assets/img/logo.png';
import dzjg from '@/assets/img/dzjg.png';
// import gwab from '@/assets/img/gwab.png';
import BorderSvg from './assets/border.svg';
import errorConfig from '@/utils/ErrorCode';
import message from '@/components/message';

import CustomInput from './components/CustomInput';

const FormItem = Form.Item;
@Form.create()
@inject('accountStore')
@observer
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: 'text',
      readOnly: true,
      loginEnable: true,
      msg: ''
    };
  }

  componentDidMount() {
    this.getVerificationImg();

    const search = window.location.search;
    const res = qs.parse(search, { ignoreQueryPrefix: true });
    console.log('errorConfig===', errorConfig);
    let isHas = false;
    if (_.has(res, 'error')) {
      const code = res.error;
      Object.keys(errorConfig).forEach((key) => {
        const reg = new RegExp(key);
        if (reg.test(code)) {
          isHas = true;
          let meg;
          if (code !== '4') {
            meg = '账号或密码错误';
          } else {
            meg = errorConfig[key];
          }
          message.warning(meg || '登录失败');
        }
      });
      if (!isHas && _.has(res, 'msg')) {
        const msg = res.msg;
        this.setState({ msg });
        // message.warning(msg || '登录失败');
      }
    }
  }

  getVerificationImg = () => {
    service
      .getVerificationImg()
      .then((res) => {
        this.setState({
          charImage: res
        });
      })
      .catch((e) => {
        console.log('errr', e.message);
      });
  };

  login(e) {
    this.props.form.validateFields((err, values) => {
      if (err) {
        e.preventDefault();
      }
    });
  }

  handleLogin = () => {
    const { form } = this.props;

    let formEle = document.querySelector('#loginForm');

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      let pwd = setEncrypt(form.getFieldValue('password'));

      form.setFieldsValue({ password: pwd }, (err) => {
        formEle.submit();
        form.setFieldsValue({ password: '' });
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { charImage } = this.state;
    let loginURL = `${window.BASE_URL === '/' ? '' : window.BASE_URL}/login`;
    let search = window.location.search;

    if (search) {
      if (window.location.search.indexOf('?') == 0) {
        search = search.substr(1);
        loginURL = loginURL + `?${encodeURIComponent(search)}`;
      } else {
        loginURL = loginURL + `${encodeURIComponent(search)}`;
      }
    }

    const form = (
      <Form
        autoComplete="off"
        onSubmit={this.login.bind(this)}
        hideRequiredMark={true}
        action={loginURL}
        method="post"
        ref={(x) => (this.formRef = x)}
        id="loginForm"
        className={style.loginForm}>
        <input type="text" style={{ display: 'none' }} />
        <input type="password" style={{ display: 'none' }} />
        <FormItem>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: '请输入用户名' }]
          })(
            <CustomInput
              size="large"
              name="username"
              placeholder="请输入用户名"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入密码' }]
          })(
            <CustomInput
              size="large"
              ref={(x) => (this.pwdRef = x)}
              name="password"
              type="password"
              autoComplete="new-password"
              placeholder="请输入密码"
              // onFocus={() =>
              //   setTimeout(() => {
              //     this.setState({
              //       readOnly: false
              //     });
              //   }, 1000)
              // }
              // onBlur={() =>
              //   this.setState({
              //     readOnly: true
              //   })
              // }
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('captcha', {
            rules: [{ required: true, message: '请输入验证码' }]
          })(
            <Row gutter={14}>
              <Col span={16} style={{ height: 40 }}>
                <CustomInput
                  size="large"
                  name="captcha"
                  placeholder="请输入验证码"
                  onPressEnter={this.handleLogin}
                />
              </Col>
              <Col span={8} style={{ height: 40 }}>
                <img
                  style={{ height: 38, cursor: 'pointer' }}
                  src={charImage}
                  onClick={this.getVerificationImg}
                  alt=""
                />
              </Col>
            </Row>
          )}
        </FormItem>
        <Button
          size="large"
          block
          disabled={!this.state.loginEnable}
          type="primary"
          onClick={this.handleLogin}
          // htmlType="submit"
          className="loginButton">
          登录
        </Button>
        <p className="tip">{this.state.msg}</p>
        {/*<a className="login-form-forgot" href="">Forgot password</a>*/}
      </Form>
    );

    return (
      <div
        className={style.loginPage}
        style={
          !!window.bgImage
            ? { backgroundImage: `url(${window.bgImage})` }
            : null
        }>
        <div className="login-header">
          <div className="header-content">
            {/* <LogoSvg
              style={{ fontSize: 43, width: 320, flexGrow: 0, flexShrink: 0 }}
            /> */}
            <img src={window.mainLogo || logo} alt="" />
            <div className="header-title">12345政务服务便民热线平台</div>
          </div>
        </div>
        <div className="login-body">
          <div className="login-left">
            <div className="page-title">欢迎登录</div>
            <div
              style={{
                fontSize: '32px',
                fontWeight: 'normal',
                textShadow: 'none'
              }}>
              12345政务服务便民热线平台
            </div>
            <BorderSvg className="left-top-border" />
            <BorderSvg className="bottom-right-border" />
          </div>
          <div className="login-form-container">
            <div className="login-form">
              <div className="form-title">账号密码登录</div>
              {form}
            </div>
          </div>
        </div>
        {/* <div className="login-bottom">
          <div className="login-bottom-left">
            <img src='https://yf12345static.yunfu.gov.cn/zhengwu/front/beian/dzjg.png' alt="" />
          </div>
          <div className="login-bottom-right">
            <p>
              <span>版权所有：政务服务数据管理局</span>
              <span>主办单位：政务服务数据管理局</span>
              <span>网站标识码：4453000050</span>
            </p>
            <p>
              <span>备案号：<a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备 2021172310号</a></span>
              <span><img src='https://yf12345static.yunfu.gov.cn/zhengwu/front/beian/gwab.png' alt="" /><a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44530202000010">粤公网安备 44530202000010号</a></span>
            </p>
            <p>建议您使用Chrome、360等主流浏览器浏览本网站</p>
          </div>
        </div> */}
      </div>
    );
  }
}
