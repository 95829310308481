import BaseService, { postFn } from '../magicPackages/BaseService';

class PostManageService extends BaseService {
  constructor() {
    super('job', {
      getPostList: '/user/center/job/GetJobList.json'
    });
  }
}

export default new PostManageService();
