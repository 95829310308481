import { configure } from 'mobx';
import globalStore from './global';
import userStore from './user';
// import roleStore from './role';
import blogStore from './blog';

import postManageStore from './postManage';
import departmentManageStore from './departmentManage';
import roleManageStore from './roleManage';
import tenantManageStore from './tenantManage';
import userManageStore from './userManage';
import accountStore from './account';

configure({
  enforceActions: 'always' // 严格模式
});

const stores = {
  blogStore,
  globalStore,
  userStore,
  // roleStore,
  postManageStore,
  departmentManageStore,
  roleManageStore,
  tenantManageStore,
  userManageStore,
  accountStore
};

export default stores;
