import { observable, action, toJS } from 'mobx';
export default class table {
  constructor(api) {
    this.getListApi = api;
  }

  @observable list;
  @observable params = {};
  // @observable getListApi;
  @observable pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    size: 'small',
    showQuickJumper: true,
    showSizeChanger: true,
    hideOnSinglePage: false,
    showTotal: (total) => `共 ${total} 条`
  };

  @action getList = (page, pageSize) => {
    let params = Object.assign({}, this.params, {
      currentPage: page || this.pagination.current,
      pageSize: pageSize || this.pagination.pageSize
    });
    return this.getListApi(params).then((rs) => {
      if (rs && rs.success) {
        this.updateList(rs);
        return rs;
      }
    });
  };

  @action onChange = (pagination, filters, sorter) => {
    if (sorter && sorter.order) {
      if (sorter.order === 'ascend') {
        this.onParamsChange('sort', 0);
      } else {
        this.onParamsChange('sort', 1);
      }
    }
    this.pagination = Object.assign({}, this.pagination, {
      current: pagination.current,
      pageSize: pagination.pageSize
    });
    return this.getList(pagination.current, pagination.pageSize);
  };

  @action onListReset = () => {
    this.list = undefined;
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
      size: 'small',
      showQuickJumper: true,
      showSizeChanger: true,
      hideOnSinglePage: false,
      showTotal: (total) => `共 ${total} 条`
    };
  };

  @action updateList = (rs = {}) => {
    this.list = (rs && rs.data) || [];

    this.pagination = Object.assign({}, this.pagination, {
      // current: rs.currentPage !== undefined ? rs.currentPage : this.pagination.current || 0,
      // pageSize: rs.pageSize !== undefined ? rs.pageSize : this.pagination.pageSize || 0,
      total:
        rs.totalCount !== undefined
          ? rs.totalCount
          : this.pagination.totalCount || 0
    });
  };

  @action operateFun = (fun, params) => {
    return fun(params).then((rs) => {
      return rs.success;
    });
  };

  @action init = (params = {}) => {
    for (let i in params) {
      let value = params[i];
      this.params[i] = value;
    }
  };

  @action onParamsChange = (params = {}) => {
    this.params = Object.assign({}, this.params, params);
  };

  @action onParamsReset = () => {
    this.params = {};
  };
}
