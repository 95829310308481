import BaseService, { postFn } from '../magicPackages/BaseService';
import axios from 'axios';

class AccountService extends BaseService {
  constructor() {
    super('', {
      login: postFn(`/login`),
      getVerificationImg: function () {
        return axios
          .get(
            `${
              window.BASE_URL === '/' ? '' : window.BASE_URL
            }/user/center/GetCaptcha.json`,
            {
              responseType: 'blob'
            }
          )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            return Promise.resolve(url);
          });
      },
      getUserInfo: `/user/center/GetUserInfo.json`,
      modifyPassWord: postFn(`/user/center/ModifyPassWord.json`),
      resetPassWord: postFn(`/user/center/ResetPassWord.json`)
    });
  }
}

export default new AccountService();
