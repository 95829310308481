import BaseService, { postFn } from '../magicPackages/BaseService';

class TenantManageService extends BaseService {
  constructor() {
    super('', {
      getList: `/api/v1/blog/getList`,
      getDetail: {
        url: `/api/v1/blog/getDetail`,
        method: 'get',
        formatParams: ''
      },

      create: postFn(`/api/v1/blog/create`),
      update: postFn(`/api/v1/blog/update`),
      multiUpdate: postFn(`/api/v1/blog/update`),
      remove: postFn(`/api/v1/blog/remove`)
    });
  }
}

export default new TenantManageService();
