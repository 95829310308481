const ERROR_CODE = {
  1: '用户不存在',
  2: 'Bad credentials',
  3: '用户已作废',
  4: '验证码错误',
  5: '您的证书无效，请核查服务器是否取得授权或重新申请证书！',
  6: '当前账号已过期，请联系管理员进行续费!',
  7: '当前账号的质检时长已用完，请联系管理员进行续费！'
};

const AUTH_ERROR_MSG = window.YUNLI_AUTH_ERROR_MSG || '';

let AUTH_ERROR_CODE = ERROR_CODE;

if (AUTH_ERROR_MSG) {
  try {
    AUTH_ERROR_CODE = JSON.parse(AUTH_ERROR_MSG.replace(/&quot;/g, '"'));
  } catch (error) {
    AUTH_ERROR_CODE = ERROR_CODE;
  }
}

export default AUTH_ERROR_CODE;
